import { suffixCategoriesRegexp } from './urls/constants';

const getParamObjectByLocationSearch = (search: string) => {
  const paramsList = search.substring(1).split('&');
  const params: { [k: string]: string } = {};
  paramsList.forEach((p) => {
    const keyValue = p.split('=');
    if (keyValue.length === 2) {
      params[keyValue[0]] = keyValue[1];
    }
  });
  return params;
};

const segmentArray = ['categories', 'tags'];
const landingPage = ['/thuong-hieu/', '/thuong-hieu-kamereo', '/nguyen-lieu-nhat-ban-gyomu-japan']

const pathnameChecker = {
  isProductPage: (currentPathname: string) => currentPathname.includes('/products'),
  isLandingPage: (currentPathname: string) => {
    return landingPage.some((page) => currentPathname.includes(page))
  },
  isHomePage: (currentPathname: string) => currentPathname === '/',
  isCategoryPage: (currentPathname: string) =>
    !pathnameChecker.isProductPage(currentPathname) &&
    segmentArray.some((segment) => suffixCategoriesRegexp[segment].codename.test(currentPathname)),
  isFavoritePage: (currentPathname: string) => currentPathname === '/favorite',
  isOtherPage: (currentPathname: string, checkingPathname: string) => currentPathname.includes(checkingPathname),
  isMarketRoutes: (currentPathname: string) =>
    pathnameChecker.isCategoryPage(currentPathname) || pathnameChecker.isOtherPage(currentPathname, '/market'),
  isProductTagPage: (currentPathname: string) =>
    suffixCategoriesRegexp.tags.codename.test(currentPathname)
};

export { getParamObjectByLocationSearch, pathnameChecker };
