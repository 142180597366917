import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const setWidgets = data => action(ActionTypes.SET_WIDGETS, data);
export const sethomePagePopup = data => action(ActionTypes.SET_HOMEPAGE_POPUP, data);

export const homeProducts = createAsyncAction(
  ActionTypes.HOME_PRODUCTS_REQUEST,
  ActionTypes.HOME_PRODUCTS_SUCCESS,
  ActionTypes.HOME_PRODUCTS_FAILURE,
)<{}, {}, { [key: string]: { data: []; error: boolean } }>();

export const setHomeFavirote = (data: any) => action(ActionTypes.HOME_FAVORITE, data);
export const homeFavorite = createAsyncAction(
  ActionTypes.HOME_FAVORITE_REQUEST,
  ActionTypes.HOME_FAVORITE_SUCCESS,
  ActionTypes.HOME_FAVORITE_FAILURE,
)<void, {}, {}>();

export const setHomeProductNote = (data: any) => action(ActionTypes.HOME_SET_PRODUCT_NOTE, data);
export const homeSetProductNote = createAsyncAction(
  ActionTypes.HOME_SET_PRODUCT_NOTE_REQUEST,
  ActionTypes.HOME_SET_PRODUCT_NOTE_SUCCESS,
  ActionTypes.HOME_SET_PRODUCT_NOTE_FAILURE,
)<void, {}, Error>();
