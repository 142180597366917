import { ContainerActions, ContainerState } from './types';
import ActionTypes from './constants';

export const initialState: ContainerState = {
  loading: false,
  widgets: [],
  sections: {},
  homePagePopup: undefined,
  loadingNote: false,
};

function homePageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  const loading = typeof state.loading === 'boolean' ? {} : state.loading;
  switch (type) {
    case ActionTypes.SET_WIDGETS:
      return { ...state, widgets: payload };

    case ActionTypes.HOME_PRODUCTS_REQUEST: {
      const keys = Object.keys(payload);
      keys.forEach((key) => {
        loading[key] = true;
      });
      return { ...state, loading: loading, sections: { ...state.sections, ...payload } };
    }


    case ActionTypes.HOME_PRODUCTS_SUCCESS: {
      const keys = Object.keys(payload);
      keys.forEach((key) => {
        if (payload[key].data.length && state.loading[key]) {
          loading[key] = false;
        }
      });
      return { ...state, loading: loading, sections: { ...state.sections, ...payload } };
    }
    case ActionTypes.HOME_PRODUCTS_FAILURE: {
      const keys = Object.keys(payload);
      const sections = { ...state.sections };
      keys.forEach(key => {
        if (!sections[key]) {
          sections[key] = payload[key];
          loading[key] = false;
        }
      });
      return { ...state, loading: loading, sections: sections };
    }

    case ActionTypes.HOME_SET_PRODUCT_NOTE_REQUEST: {
      return { ...state, loadingNote: true };
    }

    case ActionTypes.HOME_SET_PRODUCT_NOTE_SUCCESS: {
      const { note, index, section } = payload;
      const products = [...state.sections[section].data];
      products[index] = { ...products[index], note: note };
      return {
        ...state,
        sections: { ...state.sections, [section]: { data: products, error: false } },
        loadingNote: false,
      };
    }

    case ActionTypes.HOME_SET_PRODUCT_NOTE_FAILURE: {
      return { ...state, loadingNote: false };
    }

    case ActionTypes.HOME_FAVORITE_SUCCESS: {
      const { index, section, isFavorite } = payload;
      const products = [...state.sections[section].data];
      products[index] = { ...products[index], isFavorite: isFavorite };
      return {
        ...state,
        sections: { ...state.sections, [section]: { data: products, error: false } },
      };
    }

    case ActionTypes.SET_HOMEPAGE_POPUP: {
      return { ...state, loading: false, homePagePopup: payload };
    }

    default:
      return state;
  }
}

export default homePageReducer;
