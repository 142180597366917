import { Input as AInput } from 'antd';
import TrackingInput from 'components/TrackingComponents/TrackingInput';
import { ContainerState } from 'containers/MainLayout/types';
import { pick } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import translations from 'translations';
import { Category } from 'types/schema';
import getSelectedCategory from 'utils/getSelectedCategory';
import media from 'utils/mediaStyle';
import messages from 'utils/messages';
import { parse, stringify } from 'utils/queryString';
import renderCategoryName, { FilterableKeys } from 'utils/renderCategoryName';
import { ErrorBoundary } from '../CategoryNav/ErrorBoundary';
import { SearchOutlined } from '@ant-design/icons';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';
import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';
import { pathnameChecker } from 'utils/url';

const Label = styled.div`
  text-align: center;
  font-size: 14px;
  flex: 1;
  font-weight: 400;
  white-space: nowrap;
  ${media.lg`
    margin-left: 8pt;
  `};
`;

const LabelWrapper = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  padding: 8px 10px;
  ${media.lg`
    display: flex;
  `};
`;

const CategoryElment = styled.div`
  position: relative;
  background: #fcfffc;
`;
const SubCategory = styled.div<any>`
  pointer-events: none;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  left: 100%;
  z-index: 1;
  border: 1px solid #dce0e9;
  background: #fcfffc;
  opacity: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 4px 4px 8px rgba(4, 15, 15, 0.12);
  top: ${({ bottom }) => (bottom ? 'auto' : '-1px')};
  bottom: ${({ bottom }) => (bottom ? '-1px' : 'auto')};
  min-width: 134pt;
  padding: 6pt;
`;

const CategoryNavContainer = styled.nav`
  padding-right: 5px;
  border-collapse: collapse;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  .ant-menu-horizontal {
    border-bottom: none;
  }
  ${media.lg`
    flex-direction: column;
    border: 1px solid #dce0e9;
    background: transparent;
    width: auto;
  `};
  ${CategoryElment} + ${CategoryElment} {
    border-top: 1px solid #dce0e9;
  }
  ${CategoryElment}:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  ${CategoryElment}:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  ${CategoryElment}:hover ${SubCategory} {
    pointer-events: initial;
    opacity: 1;
  }
`;

const CategoryWrapper = styled.div<any>`
  overflow: auto;
  width: 100vw;
  z-index: 1;
  display: flex;
  ${media.lg`
    position: fixed;
    width: auto;
    z-index: 20;
    top: 100pt;
    left: 80pt;
  `};
  transition: left 0.3s ease-in-out;
  cursor: pointer;
`;

const Menu = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  padding-bottom: 10px;
  padding-top: 8px;
  @media (max-width: 767px) {
    padding-left: 10pt;
  }
`;

const MenuItem = styled.div`
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  &.selected {
    color: #43a047;
    border-color: #43a047;
    a div div {
      color: #43a047;
    }
  }
  a {
    color: inherit;
  }
`;

const CategoryContainer = styled.div`
  flex-shrink: 0;
  z-index: 0;
  overflow-x: hidden;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 6px 0 8px;
  @media (max-width: 767px) {
    padding: 0 10pt;
  }
`;

const CustomSearchInput = styled(TrackingInput)`
  .ant-input {
    background: none;
    border-radius: 8px;
    height: 40px;
    padding: 11px 12px;
    border: 1px solid #bdbdbd;
    @media (max-width: 576px) {
      background: #ffffff;
    }
  }
`;

const AntdInput = styled(AInput)`
  & .ant-input-search-icon {
    font-size: 18px;
    opacity: 0;
  }
  & .search-icon {
    position: absolute;
  }
`;

interface CategoryNavProps extends RouteComponentProps {
  main?: ContainerState;
}

const CategoryNavMobile: FC<CategoryNavProps> = (props) => {
  const { main, location, history } = props;
  const [keyword, setKeyWord] = useState<string>('');
  const lang = localStorage.getItem('lang') || 'vi';
  const path = pathnameChecker.isFavoritePage(location.pathname) ? '/favorite' : '';
  const isShowSearchInput = pathnameChecker.isMarketRoutes(location.pathname);
  const segment = getPathSegmentFromSlug(location);
  const searchQuery = createSearchQueryfromSlug(location, segment);
  const search = parse(searchQuery);
  const selectedCategory = useMemo(() => {
    const substate = main;
    return getSelectedCategory(
      searchQuery,
      substate && (path === '/favorite' ? substate.favoriteCategories : substate.categories),
    );
  }, [searchQuery, main?.favoriteCategories, main?.categories]);
  const currentCategories = useMemo(() => {
    if (selectedCategory) {
      return [
        {
          name: 'All',
          nameLocal: lang === 'en' ? 'All' : 'Tất cả',
          children: [],
        } as Category,
      ].concat(selectedCategory.children);
    }
    return [];
  }, [selectedCategory]);
  const filterableKeysParams = useMemo(() => {
    return {
      ...search,
      pagination: {
        page: {},
      },
      filter: pick(search.filter, Object.values(FilterableKeys)),
    };
  }, [search, FilterableKeys]);

  const onChangeSearch = (event) => {
    setKeyWord(event.target.value);
  };
  const handleOnSearch = useCallback(() => {
    if (!keyword) return;
    const searchParams = stringify({
      ...filterableKeysParams,
      q: keyword || undefined,
      favorite: undefined,
    });
    history.push({
      pathname: `/market`,
      search: searchParams,
    });
  }, [keyword, history]);
  useEffect(() => {
    if (path === '/favorite') {
      setKeyWord('');
      return;
    }
    setKeyWord(search.filter?.query || '');
  }, [location.search]);
  return (
    <ErrorBoundary>
      {isShowSearchInput && (
        <InputContainer>
          <CustomSearchInput
            onChange={onChangeSearch}
            onPressEnter={handleOnSearch}
            value={keyword}
            placeholder={`${translations(messages.search)} ${translations(messages.in)} ${renderCategoryName(
              selectedCategory || undefined,
              search,
            )}`}
            size="small"
            options={{
              action: 'Search Product',
              category: 'Market Page | Input',
            }}
            suffix={
              <SearchOutlined
                onClick={handleOnSearch}
                style={{ fontSize: '21px', color: 'rgb(67, 160, 71)', cursor: 'pointer' }}
              />
            }
          >
            <AntdInput />
          </CustomSearchInput>
        </InputContainer>
      )}
      <CategoryContainer>
        {selectedCategory && (
          <CategoryWrapper>
            <CategoryNavContainer>
              <Menu>
                {currentCategories.map((category: Category, index: number) => {
                  if (index === 0 && selectedCategory) {
                    const targetCategory = {
                      categoryName: selectedCategory.parent ? selectedCategory.parent.name : selectedCategory.name,
                      id: selectedCategory.parent ? selectedCategory.parent.id : selectedCategory.id,
                    };
                    const isSelected =
                      (selectedCategory && selectedCategory.parent) || searchQuery.indexOf('tags') > -1;
                    return (
                      <MenuItem key={index} className={isSelected ? '' : 'selected'}>
                        <Link
                          to={
                            path === '/favorite'
                              ? `${path}${
                                  selectedCategory ? `?category=${encodeURIComponent(targetCategory.categoryName)}` : ''
                                }`
                              : `/${generateSlugFromID(targetCategory?.id || 0, lang)}`
                          }
                        >
                          <LabelWrapper>
                            <Label>{category.nameLocal}</Label>
                          </LabelWrapper>
                        </Link>
                      </MenuItem>
                    );
                  }
                  let menuItemClassName = '';
                  if (selectedCategory && category && selectedCategory.name.indexOf(category.name) > -1) {
                    menuItemClassName = 'selected';
                  }
                  return (
                    <MenuItem key={index} className={menuItemClassName}>
                      <Link
                        to={
                          path === '/favorite'
                            ? `${path}?category=${encodeURIComponent(category.name)}`
                            : `/${generateSlugFromID(category?.id || 0, lang)}`
                        }
                      >
                        <LabelWrapper>
                          <Label>{category?.nameLocal}</Label>
                        </LabelWrapper>
                      </Link>
                    </MenuItem>
                  );
                })}
              </Menu>
            </CategoryNavContainer>
          </CategoryWrapper>
        )}
      </CategoryContainer>
    </ErrorBoundary>
  );
};

export default withRouter(CategoryNavMobile);
