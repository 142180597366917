import gql from 'graphql-tag';

export default gql`
  query brand($brandId: Int!) {
    brand(brandId: $brandId) {
      slug
      name
      description
      description_vi
      imageUrl
      createdAt
      updatedAt
      groupName
      productCategories {
        id
        slug
        name
        activated
      }
      id
    }
  }
`;
